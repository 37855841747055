
import { ClassFactory } from './classFactory'
import { isArray } from "./utils";

class Classes {

	constructor(ApiRootUrl, $http, $q) {
		if (typeof Classes.info === "object") {
			let cf = new ClassFactory(ApiRootUrl, $http, $q);
			let classes = {};
			cf.makeClasses(Classes.info, classes);
	      	Object.assign(this, classes)
		} else {
			(console.error||console.log).call(console, "Classes: Classes.info is not an object");
			
      	}
	}

}

Classes.setInfo = json => {
	if (typeof json === 'object' && json !== null) {
		Classes.info = json;
	} else {
		throw "setInfo: Expected an object, got " + typeof json;
	}
}

export { Classes }