

class CurrentUserPrefs {

	user;
	cache;
	

	// param: Classes - instance of  Persistent.Classes
	constructor(CurrentUser, $q) {
		this.cache = {};
		this.Promise = $q || Promise;   // implementacja promise w Angular jest zintegrowana z digest()
		if (typeof CurrentUser.instance === "object") {
			this.user = CurrentUser;
		} else {
			(console.error||console.log).call(console, "CurrentUserPrefs: CurrentUser.instance is not an object");
      	}
	}
	
	// zapisuje ustawienie value w preferencjach uzytkownika
	// value - string | number | object
	// value - null - usuwa preferencję z ustawień użytkownika
	put(key, value) {
		// do something with $http to put value
		this.cache[key] = value;
		if (this.user && this.user.instance) {
			return this.user.instance.set_preference({key:key, value:value}).then(function(){
				return true;
			});
		}
		return (this.Promise.resolve||this.Promise.when)(true);
    }
    
    // pobiera preferencję z ustawień użytkownika
    // zwraca Promise
    get(key) {
		// do something with $http to get value
		if (typeof this.cache[key] == "undefined") {
			this.cache[key] == null;
			if (this.user && this.user.instance && this.user.instance.prefs) {
				for (let name in this.user.instance.prefs) {
					this.cache[name] = this.user.instance.prefs[name];
				}
			}
		}
		return (this.Promise.resolve||this.Promise.when)(this.cache[key]);
    }

}


export { CurrentUserPrefs }

