



if (!Object.assign) {
  Object.defineProperty(Object, 'assign', {
    enumerable: false,
    configurable: true,
    writable: true,
    value: function(target) {
      'use strict';
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert first argument to object');
      }

      var to = Object(target);
      for (var i = 1; i < arguments.length; i++) {
        var nextSource = arguments[i];
        if (nextSource === undefined || nextSource === null) {
          continue;
        }
        nextSource = Object(nextSource);

        var keysArray = Object.keys(Object(nextSource));
        for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
          var nextKey = keysArray[nextIndex];
          var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
          if (desc !== undefined && desc.enumerable) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
      return to;
    }
  });
}

import { entries, isArray } from "./utils";


class ClassFactory {
  
  

  constructor(ApiRootUrl, $http, $q) {
    this.ApiRootUrl = ApiRootUrl;
    this.$http = $http;
    this.Promise = $q || Promise;   // implementacja promise w Angular jest zintegrowana z digest()
    
    this.urlToClasses = {};
  }


  loadClasses(url) {
    if (url in this.urlToClasses) {
    	// Promise.resolve == $q.when
      return (this.Promise.resolve||this.Promise.when)(this.urlToClasses[url]);
    }
    
    let absolute_url = this.ApiRootUrl + (url || '/api/v1/meta/classes');
    return this.$http.get(absolute_url).then((response) => {
      let info = typeof response.data == "string" ? JSON.parse(response.data) : response.data;
      console.debug("Loaded url", url);
      
      let classes = {};
      this.makeClasses(info, classes);
      
      this.urlToClasses[url] = classes;
      
      return classes;
    }, function(error){
		// failed to load classes
		console.debug("Failed to load url", url, error);
		return this.Promise.reject(error);
	});
  }
  
  classnameToPath(classname) {
		return classname.replace(/([A-Z])/g, '-$1').replace(/^-/,'').toLowerCase();
  }
  
  getInstance(classes, data) {
    let classname = data.__class;
    if (!classname) {
    	throw "getInstance: No __class property in object";
    }
    if (!(classname in classes)) {
		//throw "getInstance: Unknown class: " + classname;
		console.warn("getInstance: Unknown class: " + classname + ". Returning raw data...")
		return data;
	}
	if (typeof data.__id == "undefined" && typeof data.__vid == "undefined") {
		//throw "No __id in data to instantiate class: " + classname;
		return classes[classname].create(data);
	}
	if ((data.__vid || data.__id) === null) {
		return null;
	}
	let obj = classes[classname].get(data.__vid || data.__id, data);
	return obj;
  }
  
  activateClasses(classes, data) {
  	if (data instanceof Object) {
		if (isArray(data)) {
			for (let i=0; i<data.length; i++) {
				if (data[i].__class) {
					data[i] = this.getInstance(classes, data[i]);
				}
			}
		} else if (data.__class) {
			data = this.getInstance(classes, data);
		} else {
			for (let key in data) {
				if (data[key] instanceof Object && data[key].__class) {
					data[key] = this.getInstance(classes, data[key]);
				} else {
					this.activateClasses(classes, data[key]);
				}
			}
		}
	}
	return data;
  }

  // wymuś ponowne pobranie obiektów, po zapisie do bazy
  setDirty(classes) {
  	for (let [classname, klass] of entries(classes)) {
		for (let [id, obj] of entries(klass.cache)) {
			if (obj.__fetched) delete obj.__fetched;
		}
  	}
  }

  makeClasses(info, classes) {
    
    for (let [classname, classinfo] of entries(info)) {
      
      if (classname[0] == '$') return;  // Angular stuff
      classes[classname] = this.makeClass(classname, classinfo, classes);
      
    }
    return classes;
  }
  
  
  makeClass(classname, classinfo, classes) {
    let factory = this;
    let klass;
    
    console.debug('Creating class:', classname);
    
    try {
      eval(`klass = function ${classname}() {}`);
      //klass = eval(classname);
    }
    catch (e) {
      klass = function () {};
    }
    
    // Class information
    for (let [key, value] of entries(classinfo)) {
      klass[key] = value;
    }
    
    // Static methods
    klass.classname = classname;
    
    klass.cache = {};
    
    
    
    // Datastore methods
    klass.get = (vid, data = null) => {
    	if (data && data.__classname) {
    		// actual class instance
    		return data;
    	}
    	if (!klass.cache[vid]) {
    		klass.cache[vid] = new klass();
    		data = data || {__vid: vid};
    		data.__class = klass.classname;
    	}
    	let obj = klass.cache[vid];
    	if (data && typeof data=="object") obj.fill(data);
    	return obj;
    }
    
    // create new instance of the class
    klass.create = (data = null) => {
    	if (data && data.__classname) {
    		// actual class instance
    		return data;
    	}
    	let obj = new klass();
    	// assign perms
    	if (klass.perms && klass.perms.create) {
    		obj.__perms = klass.perms.create;
    	}
    	// fill data
    	if (data) {
    		if (data.__vid || data.__id || data.id) {
    			throw "Class create: data contains id information";
    		}
    		data.__class = klass.classname;
    		obj.fill(data);
    	}
    	return obj;
    }
    
    klass.getObjectPayload = (obj, group = null, omit_fieldname = null) => {
    	let payload = {};
    	if (obj.__vid) payload.__vid = obj.__vid;
		if (obj.__id) payload.__id = obj.__id;
		if (obj.__class) payload.__class = obj.__class;
    	for (let field of klass.get_fields(group)) {
    		let fieldname = field.name;
    		let value = obj[fieldname];
    		if (typeof value == "undefined") continue;
    		
    		if (fieldname == omit_fieldname) continue;
    		
			if (!field.join) {
				payload[fieldname]=value;  // not a reference type
			}
			
			if (field.join && field.join.type == 'foreignKey') {
				if (value && (value.__vid != null || value.__id != null || value.id != null || value.__class != null)) {
					payload[fieldname] = {}
					if (value.getClass) payload[fieldname] = value.getClass().getObjectPayload(value, group || 'essential');
					if (value.__CONTEXT_OBJECT__) payload[fieldname].__CONTEXT_OBJECT__ = value.__CONTEXT_OBJECT__;
				}
			}
			
			if (field.join && field.join.type == 'backRef') {
				var ret = [];
				if (value && value.length >= 0) {
					for (let item of value) {
						ret.push(classes[field.join.foreignClass].getObjectPayload(item, group, field.join.backref));
					}
				}
				payload[fieldname] = ret;
			}
    	}
    	return payload;
    }
    
    klass.saveObject = (obj) => {
    	// TODO - validation
    	let orig_vid = obj.__vid;
    	let config = {
			method: 'post',
			url: klass.ApiRootPath + (orig_vid? ('/' + orig_vid) : ''),
			data: klass.getObjectPayload(obj)
		}
		return factory.$http(config).then((result) => {
			
			factory.setDirty(classes);  // kasuj wszystko, nie wiadomo co się zmieniło :)
			
			obj.fill(result.data, true);  // aktualizuje backref, ale poprzez wymianę niezapisanych obiektów
			obj.__fetched = (this.Promise.resolve||this.Promise.when)(obj);
			if (!orig_vid) {
				// utworzono nowy obiekt - aktualzuj cache
				klass.cache[obj.__vid] = obj;
			}
			
			return obj;
		});
    }
    
    klass.deleteObject = (obj) => {
    	let orig_vid = obj.__vid;
    	let config = {
			method: 'delete',
			url: klass.ApiRootPath + (orig_vid? ('/' + orig_vid) : ''),
		}
		return factory.$http(config).then((result) => {
			delete obj.__fetched;
			if (!orig_vid) {
				// skasowano obiekt - aktualzuj cache
				delete klass.cache[obj.__vid];
			}
			
			factory.setDirty(classes);  // kasuj wszystko, nie wiadomo co się zmieniło :)
			
			return true;
		});
    }
    
    klass.find = (params) => {
    	let config = {
			method: 'get',
			url: klass.ApiRootPath,
			params: params
		}
		return factory.$http(config).then((result) => {
			let ret = [];
			if (isArray(result.data)) {
				for (let item of result.data) {
					ret.push(factory.getInstance(classes, item));
				}
			}
			return ret;
		});
    }
    
    let intersection = (a1, a2) => {
    	let res = [];
    	for (let item of a1) {
    		if (a2.indexOf(item) >= 0) res.push(item);
    	}
    	return res;
    }
    
    // Api methods
    klass.get_fields = (group) => {
    	group = typeof group == "string" ? [group] : group;
		var fields = [];
		for (var fname in klass.fields) {
			if (group == null || intersection(klass.fields[fname].groups||[], group).length > 0) fields.push(klass.fields[fname]);
		}
		return fields;
	}
	
	// pobieranie listy metod (z opcjonalnym filterm grupy)
	klass.get_methods = (group) => {
		group = typeof group == "string" ? [group] : group;
		var methods = [];
		for (var fname in klass.methods) {
			if (group == null || intersection(klass.methods[fname].groups||[], group).length > 0) {
				var m = klass.methods[fname];
				m.path = m.field? (m.field + '.' + m.name) : m.name;
				methods.push(m);
			}
		}
		return methods;
	}
	
	klass._method_to_form = (method, object) => {
		var form = {
			'name': method.title,
			'prompt': method.prompt,
			'btn': method.title,
			'templateUrl': method.templateUrl || null,  // used in form-service
			'verb': method.name,
			'path': method.path,
			'perm': method.perm,
			'context': method.is_static? klass : object,
			'params': []
		}
		for (var p in method.params) {
			if (!method.params[p].field) continue;
			form.params.push(method.params[p].field);
		}
		return form;
	}
	
	klass.get_forms = (group, object) => {
		var methods = klass.get_methods(group);
		var forms = [];
		for (var i=0; i<methods.length; i++) {
			var method = methods[i];
			if (!method.is_static && !object) continue;
			let form = klass._method_to_form(method, object);
			forms.push(form);
		}
		return forms;
	}
	
	klass.get_form = (method_name, object) => {
		var methods = klass.get_methods();
		var forms = [];
		for (var i=0; i<methods.length; i++) {
			var method = methods[i];
			if (method.name == method_name) {
				return klass._method_to_form(method, object);
			}
		}
		return null;
	}
	
	klass.get_text_search_query = () => {
		var fields = klass.get_fields('list');
		var textfields = ["''"];  // in case of no fields
		for (var i in fields) {
			var f = fields[i];
			if (f.type == 'TextField' || f.type == 'Field') textfields.push("coalesce(" + f.name + ",'')");
			if (f.type == 'IntField' || f.type == 'DecimalField') textfields.push("coalesce(" + f.name + "::text,'')");
			if (f.type == 'ReferenceField') {
				if (classes[f.join.foreignClass].fields['title']) textfields.push("coalesce(" + f.name + ".title,'')");
				else textfields.push("coalesce((" + f.sort_expr + ")::text,'')");
			}
			if (f.type.match(/CalculatedField$/)) textfields.push("coalesce((" + f.sort_expr + ")::text,'')");
		}
		return textfields.join(' || ') + ' ~* ?';
	};
	
	klass.get_sortcol = (fieldname) =>{
		var field = klass.fields[fieldname];
		if (!field) return null;
		return field.sort_expr;
	}
	
	klass.clone_instance = (original, data = null, group = 'edit') => {
		return original.fetch(false, 2).then((original) => {
			data = data || {};
			let waitables = [];
			var fields = klass.get_fields(group);
			for (let field of fields) {
				let value;
				if (!field.join) {
					value = original[field.name];  // not a reference type
				}
				
				if (field.join && field.join.type == 'foreignKey') {
					value = original[field.name];  // do not clone foreign key objects
				}
				
				if (field.join && field.join.type == 'backRef') {
					// cascade clone to editable backref objects
					value = [];
					let foreignKlass = classes[field.join.foreignClass];
					if (!foreignKlass) throw "Invalid backRef, no foreignClass: "+field.join.foreignClass;
					if (original[field.name] && original[field.name].length >= 0) {
						for (let item of original[field.name]) {
							let promise = foreignKlass.clone_instance(item, null, group).then((instance) => {
								instance[field.join.backref] = null;
								value.push(instance);
								return (parent) => {instance[field.join.backref] = parent;}
							});
							waitables.push(promise);
						}
					}
				}
				data[field.name] = value;
			}
			return factory.Promise.all(waitables).then((initializers) => {
				let instance = klass.create(data);
				for (let initializer of initializers) {
					initializer(instance);
				}
				return instance;
			});
		});
	}
	
	klass.get_url = function(verb, params) {
		var to_query_string = function(obj) {
			  var str = [];
			  for(var p in obj)
			    if (obj.hasOwnProperty(p)) {
			      var v = typeof obj[p] == "object"? angular.toJson(obj[p]) : obj[p];
			      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(v));
			    }
			  return str.join("&");
		}
		let url = this.ApiRootPath + '/'+verb+'?' + to_query_string(params);
		return url;
	} 
	
	var class_path = factory.classnameToPath(classname);
	klass.ApiRootPath = `${factory.ApiRootUrl}/api/v1/${class_path}`
	
	// remote static methods
	let methods = klass.get_methods();
	for (let method of methods) {
		if (!method.is_static || method.http['default']) continue;
		
		(function(method, classname){
			var path = method.path.replace('.', '__');
			//console.debug('Adding class method:', path, method);
			klass[path] = function(args){
				var config = {
					method: method.http.method.toLowerCase(),
					url: `${klass.ApiRootPath}/${method.path}`
				}
				if (config.method == 'post' || config.method == 'put') {
					config.data = args;
				} else {
					config.params = args;
				}
				
				let promise = factory.$http(config).then((result) => {
					if (config.method != 'get') {
						factory.setDirty(classes);  // po metodzie POST wszystko się mogło zmienić.
					}
					let data = result.data;
					return factory.activateClasses(classes, data);
				});
				
				return promise;
			}
			klass[path].method = method;
		})(method, classname);
	}
	
	// remote instance methods
	for (let method of methods) {
		if (method.is_static || method.http['default']) continue;
		(function(method, classname){
			var path = method.path.replace('.', '__');
			var global_config = {
				method: method.http.method.toLowerCase(),
			}
			//console.debug('Adding class method:', path, method);
			klass.prototype[path] = function(args){
				var config = Object.assign({}, global_config);
				config.url = `${klass.ApiRootPath}/${this.__vid}/${method.path}`;
				if (config.method == 'post' || config.method == 'put') {
					config.data = args;
				} else {
					config.params = args;
				}
				
				let promise = factory.$http(config).then((result) => {
					if (config.method != 'get') {
						factory.setDirty(classes);  // po metodzie POST/PUT/DELETE wszystko się mogło zmienić.
					}
					let data = result.data;
					return factory.activateClasses(classes, data);
				});
				
				return promise;
			}
			klass.prototype[path].method = method;
		})(method, classname);
	}
	
	let mapField = function(fieldname, value, oldvalue) {
		var field = klass.fields[fieldname];
		if (!field) {
			console.warn('Attempted to get() nonexisting field: ' + fieldname);
			return null;
		}
		
		if (!field.join) {
			return value;  // not a reference type
		}
		
		if (field.join && field.join.type == 'foreignKey') {
			if (value === null) return null;
			if (value.__vid != null || value.__id != null) {
				return factory.getInstance(classes, value);
				//return classes[field.join.foreignClass].get(value.__vid, value);
			} else {
				return null;
			}
		}
		
		if (field.join && field.join.type == 'backRef') {
			var ret = oldvalue || [];
			ret.length = 0;
			if (value && value.length >= 0) {
				for (let item of value) {
					//ret.push(classes[field.join.foreignClass].get(item.__vid, item));
					ret.push(factory.getInstance(classes, item));
				}
			}
			return ret;
		}
	}
    
    
    // Instance methods
	klass.prototype.__classname = classname;
    
    
    
    
    
    /*
     * This method updates the object with data provided from an external source, API
     */
    klass.prototype.fill = function (data, force) {
    	// extract metadata
    	if (data.__class) this.__class = data.__class;
    	if (data.__id) this.__id = data.__id;
    	if (data.__vid) this.__vid = data.__vid;
    	if (data.__title) this.__title = data.__title;
    	if (data.__perms) this.__perms = data.__perms;
    	if (data.__callstatus) this.__callstatus = data.__callstatus;
    	// update actual object data
    	for(let fieldname in data) {
    		if (!klass.fields[fieldname]) continue;  // only actual fields
    		// don't update user-modified fields, only unseen or unmodified.
    		// this is important when someone has modified things, yet more information comes from API.
    		if (force || klass.fields[fieldname].join || typeof this[fieldname] == "undefined" || this[fieldname] == this._original[fieldname]) {
    			this[fieldname] = mapField(fieldname, data[fieldname], this[fieldname]);
    		}
    	}
    	// update the original data cache
    	this._original = this._original || {};
    	Object.assign(this._original, data);
    };
    
    
    /*
     * Rollback user changes to the object
     */
    klass.prototype.rollback = function () {
    	for(let fieldname in this._original) {
    		if (!klass.fields[fieldname]) continue;  // only actual fields
    		this[fieldname] = mapField(fieldname, this._original[fieldname], this[fieldname]);
    	}
    };
    
    klass.prototype.tmp_title = function() {
		return this.title;
	}
	
	klass.prototype.has_perm = function(perms) {
		if (typeof perms == "string") perms = [perms];
    	for (var i=0; i<perms.length; i++) {
    		if (this.__perms[perms[i]]) return true;
    	}
    	return false;
	}
	
	klass.prototype.get_forms = function(group) {
		return klass.get_forms(group, this);
	}
	
	klass.prototype.get_form = function(method_name) {
		return klass.get_form(method_name, this);
	}
	
	klass.prototype.parent_field = function() {
		var field;
		for (var i in klass.fields) {
			if (klass.fields[i].is_parent) {
				field = klass.fields[i];
				break;
			}
		}
		return field;
	}
	
	klass.prototype.get_parent = function() {
		//return null;
		var field = this.parent_field();
		if (field && this[field.name]) {
			return this[field.name];
		}
		return null;
	}
	
	// Zwraca tą samą instancję po pobraniu danych z serwera
	klass.prototype.fetch = function(force, levels=1) {
		if (!this.__fetched && this._original.__loaded) {
			this.__fetched = (factory.Promise.resolve||factory.Promise.when)(this);
		}
		if (this.__fetched && !force) {
			return this.__fetched.then(()=>{return this});
		}
		let config = {
			method: 'get',
			url: klass.ApiRootPath + '/' + this.__vid + (levels? ('?levels=' + levels) : '')
		}
		return this.__fetched = factory.$http(config).then((result) => {
			this.fill(result.data);
			return this;
		});
	}
	
	// eg. get('landlord')
	//     get('landlord.last_name'), etc.
	klass.prototype.get = function(fieldpath) {
		var path = fieldpath.split('.');
		var fieldname = path.shift();
		return new factory.Promise((resolve, reject) => {
			let loaded
			if (typeof this[fieldname] == "undefined") {
				// field is curently not loaded
				loaded = this.fetch();
			} else {
				// field is loaded
				loaded = (factory.Promise.resolve||factory.Promise.when)(this);
			}
				
			loaded.then(() => {
				if (path.length > 0) {
					if (this[fieldname] === null) {
						resolve(null);
					} else {
						this[fieldname].get(path.join('.')).then(resolve, reject);
					}
				} else {
					resolve(this[fieldname]);
				}
			}, reject);
		})
	}
	
	
	/*var define_properties = function() {
		for (let field_name in klass.fields) {
			(function(field_name){
				console.log('Defining property ', field_name);
				var field = klass.fields[field_name];
				Object.defineProperty(klass.prototype, field_name, {
		            get: function(){ return this.get(field_name) },
		            set: function(new_value){
		                this._data[field_name] = new_value;
		            },
		            enumerable: true,
					configurable: true,
		        });
		    })(field_name);
	    }
	}
	define_properties()
	*/
    
    klass.prototype.save = function(...args) {
    	args.unshift(this);
    	return klass.saveObject(...args);
    }
    
    klass.prototype.delete = function() {
    	return klass.deleteObject(this);
    }
    
    klass.prototype.getClass = function() {
    	return klass;
    }
    
    return klass;
    
    // TODO
    /*
     x usunąć define_properties, przy fill przerabiać referencje na obiekty
     x dodać fetch() - wczytuje obiekt -> Promise.
     X dodać metody instancji
     * opracować metodę wykrywania zmian w obiekcie, observe?
    */
    
  }

}



export { ClassFactory }








